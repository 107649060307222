.Home {
  margin: auto;
  width: auto;
  max-width: 50rem;
  overflow: visible;
}

.Home-links {
  font-size: 2rem;
  height: 7rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 20rem;
  justify-items: center;
  text-align: center;
}

.Home-link {
  color: white;
}

.Home a {
  text-decoration: none;
  opacity: 0.9;
}

.Home a:hover {
  opacity: 1;
}

a.Home-token-top-link {
  color: #fff185;
}

a.Home-token-bottom-link {
  color: #fa7a89;
}

a.Home-lev-top-link {
  color: #00ecfc;
}

a.Home-lev-bottom-link {
  color: #00a6e3;
}

.Home-logo-container {
  margin-bottom: 3rem;
}

.Home-logo {
  margin-top: 2rem;
  text-align: center;
  font-size: 5rem;
  font-family: 'Source Code Pro';
}

.Home-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 5rem;
}

.Home-chart-info {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem;
  opacity: 0.8;
  padding-left: 5rem;
}

.Home-row-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.Home-row-description {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .Home {
    padding: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .Home-row {
    grid-template-columns: 1fr;
  }
  .Home-links {
    height: 2rem;
  }
  .Home-chart-info {
    padding-left: 0;
  }
}
