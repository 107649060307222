html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Source Code Pro';
  background: #101014;
}

select {
  font-family: 'Source Code Pro';
  background: #404042;
  color: white;
  font-size: 1rem;
  border-radius: 9px;
  padding: 0.5rem 0.5rem;
  padding-right: 2rem;
  border: none;
  -webkit-appearance:none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.select-caret {
  pointer-events: none;
  position: absolute;
  right: 0.7rem;
  top: 0.75rem;
  font-size: 0.8rem;
  opacity: 0.8;
}

select:focus {
  outline: 0;
}

.radial-option {
  cursor: pointer;
  vertical-align: middle;
  border-radius: 1rem;
  border: 2px solid white;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
  position: relative;
}

.radial-option-center {
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  border-radius: 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  background: white;
  display: none;
}

.radial-option.active .radial-option-center {
  display: block;
}

.modal-container {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

input[type=checkbox] {
  vertical-align: middle;
  margin-bottom: 0.3rem;
}

.modal-box {
  z-index: 21;
  max-width: 80%;
  padding: 1.5rem;
  border-radius: 12px;
  background: #18191f;
  width: 30rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-buttons {
  text-align: right;
}

.modal-buttons button {
  margin-left: 1rem;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #404142;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f7173;
  border-radius: 1rem;
  border: 1px solid #373838;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #87888a;
}

a {
  color: #6b81ff;
  font-weight: bold;
}

a.plain, .plain {
  color: white;
  text-decoration: none;
  font-weight: normal;
  opacity: 0.9;
}

a.plain:hover, .plain:hover {
  opacity: 1;
}

a.simple {
  color: white;
  font-weight: normal;
}

.simple {
  text-decoration: underline;
}

table td {
  padding-right: 0.5rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

table td.compact {
  padding: 0;
}

button, input {
   font-family: inherit;
   font-weight: bold;
   font-size: 1rem;
   color: white;
}

button {
  cursor: pointer;
  padding: 0.5rem 0;
  border: none;
  background: none;
  color: white;
}

.Banner {
  padding: 0.7rem 1rem;
  border-radius: 12px;
  margin-right: 0.7rem;
  background: #18191f;
}

button.button-outline, a.button-outline {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid #212121;
  border-radius: 12px;
  height: 3.3rem;
  width: 13rem;
  display : flex;
  align-items : center;
  justify-content: center;
  color: white;
}

button.button-outline:hover, a.button-outline:hover {
  background: #18191f;
}

button.button-outline:focus, a.button-outline:focus {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid #212121;
  border-radius: 12px;
  height: 3.3rem;
  width: 13rem;
  display : flex;
  align-items : center;
  justify-content: center;
  color: white;
}

button.button-outline-compact {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  padding: 0.5rem 1rem;
}

button.button-outline-compact:hover {
  background: rgba(255, 255, 255, 0.05);
}

button.button-outline-compact:disabled {
  background: none;
  cursor: not-allowed;
}

button.button-outline-compact:focus {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  padding: 0.5rem 1rem;
}

button.button-text {
  height: 3.3rem;
  width: 5rem;
  text-align: left;
  opacity: 0.5;
}

button.button-text:active {
  opacity: 1;
}

button.button-text.active {
  opacity: 1;
}

button.button-primary, .button-primary {
  display: block;
  width: 100%;
  border-radius: 12px;
  height: 3.5rem;
  background-color: #3a49f2;
}

button.button-primary:hover {
  background-color: #3d4dff;
  opacity: 1;
}

button.button-primary:active, button.button-primary:hover, button.button-primary:focus {
  border-radius: 12px;
}

button.button-primary:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.button-container-primary {
  padding: 1rem;
}

.hr {
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
}

.clickable {
  cursor: pointer;
}

.inline-button {
  cursor: pointer;
}

input {
  background: none;
  border: none;
  padding: 0.5rem 0;
}

input.input-outline {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  display: block;
}

input.input-outline.inactive {
  color: rgba(255, 255, 255, 0.7);
}

input.input-outline:focus {
  outline: none;
  border-color: rgba(58, 78, 242, 0.5);
}

button:hover {
  opacity: 0.8;
}

input:focus {
  outline: 0;
}

button:active {
  opacity: 0.6;
}

button:active, button:focus {
  border: none;
  border-radius: 0;
  outline: 0;
}

.Page-content {
  height: 100%;
  display: grid;
  justify-items: center;
  overflow: auto;
}

.Box {
  position: relative;
  z-index: 1;
  margin: 1rem;
  margin-top: 0;
  padding: 1rem;
  width: 50rem;
  max-width: 50rem;
  line-height: 1.5rem;
}

.Box-title {
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  height: 2rem;
}

.Box-subtitle {
  height: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.Box-title, .Box-subtitle {
  color: #5df2f5;
}

.Box-title-symbol {
  color: #5ffa67;
}

.green-text {
  color: #00c200;
}

.Box-error {
  display: inline-block;
  background: none;
}

.Toastify__toast {
  font-family: inherit;
  box-shadow: none;
  min-height: 3rem;
  padding: 0.7rem;
  cursor: default;
  border: 1px solid rgb(30, 30, 30);
  border-radius: 0.3rem;
  padding-top: 2rem;
  font-size: 1rem;
  box-shadow: 0px 5px 15px 6px rgba(0, 0, 0, 0.2);
  background: #080a0a;
}

.Toastify__toast--error {
  background: #080a0a;
  color: #c91b00;
}

.Toastify__toast--success {
  background: #080a0a;
  color: #00c200;
}

.Toastify__close-button:hover {
  background: rgba(0, 0, 0, 0);
}

.Toastify__close-button:focus {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  .Box {
    width: auto;
    margin-top: 1rem;
  }
}

@media (max-width: 900px) {
  .Box {
    width: 90%;
    margin-top: 1rem;
  }
}
