.Gambit input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 10rem;
}

button.Gambit-button {
  display: inline-block;
  width: auto;
  padding: 1rem 1.5rem;
  margin-right: 0.5rem;
}

.Gambit-max-value {
  opacity: 0.7;
  cursor: pointer;
}

.Gambit-form {
  display: none;
}

.Gambit-submit-container {
  display: none;
}

.Gambit-max-value:hover {
  opacity: 0.9;
}

.Gambit-purchase-options {
  /* display: none; */
  grid-template-columns: 1fr 1fr 1fr;
}

.Gambit-purchase-option {
  border: 1px solid #2d2d2d;
  border-radius: 9px;
  padding: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.Gambit-purchase-option:hover {
  background: rgba(84,86,214,0.1);
}

.Gambit-purchase-option.active {
  background: linear-gradient(90deg, rgba(95,25,194,1) 0%, rgba(96,57,204,1) 44%, rgba(84,88,214,1) 100%);
  box-shadow: 0 0 4px 6px rgba(84,88,214,0.2);
}

.Gambit-purchase-option-title {
  font-size: 1.2rem;
  font-weight: bold;
}
