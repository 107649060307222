@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/source-code-pro/source-code-pro-v13-latin-600.woff2') format('woff2');
  src: url('./fonts/source-code-pro/source-code-pro-v13-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/source-code-pro/source-code-pro-v13-latin-700.woff2') format('woff2');
  src: url('./fonts/source-code-pro/source-code-pro-v13-latin-700.woff') format('woff');
}
