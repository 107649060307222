.App {
  height: 100vh;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.App-info-row {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  grid-column-gap: 0.5rem;
}

.App-info-label {
  opacity: 0.7;
}

.App-toast-close-bar {
  text-align: center;
  color: #ababab;
  white-space: nowrap;
  position: absolute;
  background: #18191f;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  font-weight: normal;
  font-size: 0.8rem;
  font-family: inherit;
}

.App-toast-close-button {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 7px;
  width: 13px;
  height: 13px;
  background: #ff524d;
  border-radius: 15px;
}

.App-toast-close-x {
  position: absolute;
  font-weight: bold;
  opacity: 0.8;
  color: #630000;
  font-size: 7px;
  left: 3px;
  top: 3px;
  display: none;
}

.App-toast-close-button:hover .App-toast-close-x {
  display: block;
}

.App-content {
  min-height: 0;
}

.App-header {
  position: relative;
  z-index: 1;
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: 1fr repeat(6, auto);
  padding: 0 0.5rem;
  background: #18191f;
  color: #b8b8b8;
  padding-right: 1rem;
}

.App-background {
  display: none;
  z-index: 0;
  position: fixed;
  left: -20%;
  right: -100%;
  top: -100%;
  bottom: -20%;
  background: radial-gradient(circle, rgba(84,84,214,0.2) 0%, rgba(91,59,204,0.05) 44%, rgba(0,0,0,1) 100%);
  opacity: 0.5;
  pointer-events: none;
}

.App-highlight {
  display: none;
  z-index: 0;
  position: fixed;
  left: -80%;
  right: -20%;
  top: -20%;
  bottom: -100%;
  background: radial-gradient(circle, rgba(173,9,168,0.1) 0%, rgba(95,40,199,0.1) 44%, rgba(0,0,0,1) 100%);
  opacity: 0.5;
  pointer-events: none;
}

.App-header-arrow {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 11;
  color: #b8b8b8;
}

.App-header-arrow.opened {
  opacity: 0.5;
}

.App-header-mobile {
  display: none;
  font-size: 1.1rem;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  cursor: pointer;
}

.App-header-mobile a {
  font-weight: normal;
}

.App-header-drawer {
  position: absolute;
  left: 0;
  right: 0;
  top: 3rem;
  padding-top: 1rem;
  background: #18191f;
  border-bottom: 1px solid rgb(100, 100, 100);
  text-align: center;
  z-index: 5;
  padding-bottom: 0.5rem;
}

.App-drawer-enter {
  transform: translateY(-150%);
}

.App-drawer-enter-active {
  transform: translateY(0);
  transition: transform 350ms;
}

.App-drawer-enter-done {
  transform: translateY(0);
}

.App-drawer-exit-active {
  transform: translateY(-150%);
  transition: transform 350ms;
}

.App-header-drawer a {
  display: block;
  padding: 0.5rem 0.5rem;
  text-decoration: none;
}

.App-logo, .App-logo-mobile {
  font-weight: bold;
}

.App-header a, .App-logo-mobile {
  font-weight: normal;
  text-decoration: none;
  padding: 1rem 1rem;
  color: #b8b8b8;
}

.App-header-drawer a {
  color: #b8b8b8;
}

.App-header a.active, .App-header-drawer a.active {
  color: #ededed;
}

.App-header-mobile-border {
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 15;
  background: black;
}

.App-logo-mobile {
  position: relative;
  z-index: 15;
}

.App-header-mobile-background {
  background: #18191f;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.App-card-row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
}

.App-card {
  border: 1px solid #2d2d2d;
  border-radius: 9px;
  margin-right: 1rem;
  padding: 1rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}

.App-card-options {
  margin-top: 0.5rem;
}

.App-card-option {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 1rem;
}

@media (max-width: 868px) {
  .App {
    height: auto;
  }
  .App-header {
    display: none;
  }

  .App-header-mobile {
    display: grid;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}
