.ExchangeBeta {
}

.ExchangeBeta-box {
  border-radius: 12px;
  background: #18191f;
}

.ExchangeBeta-banner {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 0.5rem;
  line-height: 1.8rem;
}

.ExchangeBeta-close-icon {
  display: block;
  margin-top: 0.25rem;
  cursor: pointer;
}

a.ExchangeBeta-pending-tx.button-outline.active {
  color: rgba(107, 129, 255, 1);
}

a.ExchangeBeta-title-link {
  display: inline-block;
  margin-left: 0.5rem;
  color: white;
  text-decoration: none;
  vertical-align: middle;
  font-size: 0.9rem;
}

.ExchangeBeta-box-info {
  background: #18191f;
  z-index: 3;
  border-radius: 12px;
  padding: 1rem;
  text-align: left;
  position: absolute;
  right: 100%;
  width: 20rem;
  top: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: none;
}

.ExchangeBeta-box-stats:hover .ExchangeBeta-box-info {
  display: block;
}

.ExchangeBeta-stats-updated-time {
  font-size: 0.8rem;
}

.ExchangeBeta-chainlink-logo-box img {
  height: 3rem;
}

.ExchangeBeta-chainlink-logo-box {
  margin-bottom: 0.3rem;
}

.ExchangeBeta-chainlink-small-box {
  padding: 0.5rem;
  padding-left: 1rem;
  display: none;
}

.ExchangeBeta-chainlink-box {
}

.ExchangeBeta-bear-box {
}

.ExchangeBeta-bull-box {
}

.ExchangeBeta-price-box {
}

.ExchangeBeta-chainlink-small-box img {
  height: 2rem;
}

.ExchangeBeta-chainlink-logo-small-box {
  margin-bottom: 0.3rem;
}

.ExchangeBeta-box-stats {
  position: relative;
  margin-left: auto;
  text-align: center;
  width: 13rem;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem;
  margin-bottom: 0.8rem;
}

.ExchangeBeta-wallet-box {
  width: 24rem;
  margin-top: 0.5rem;
}

.ExchangeBeta-wallet-box table {
  width: 100%;
  padding-bottom: 0.3rem;
}

.ExchangeBeta-wallet-icon {
  display: inline-block;
  margin-right: 0.6rem;
  margin-bottom: -0.1rem;
}

.ExchangeBeta-wallet-box table td {
  padding-top: 0rem;
  padding-bottom: 0.2rem;
}

.ExchangeBeta-wallet-box button {
  margin-right: 0.5rem;
  font-size: 0.85rem;
}

.ExchangeBeta-wallet-button-box {
  text-align: right;
}

.ExchangeBeta-wallet-label {
  padding-top: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.8;
  padding-left: 1.5rem;
}

.ExchangeBeta-wallet-value {
  padding-left: 1.5rem;
}

.ExchangeBeta-box-stats-number {
  font-size: 1.3rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.ExchangeBeta-box-stats-label {
  font-size: 0.9rem;
  opacity: 0.8;
}

.ExchangeBeta-layout {
  width: 100%;
}

.ExchangeBeta-title {
  color: white;
  font-size: 1.1rem;
}

.ExchangeBeta-title-content {
}

.ExchangeBeta-login {
  float: right;
  margin-left: auto;
  font-size: 1rem;
}

.ExchangeBeta-widget {
  width: 24rem;
  padding-bottom: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 40px;
  margin-bottom: 1rem;
}

.ExchangeBeta-widget-left {
  margin-left: 1.5rem;
}

.ExchangeBeta-form-top {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
}

.ExchangeBeta-form {
  width: 100%;
}

.ExchangeBeta-form-left {
  padding-left: 1.5rem;
  width: 10rem;
}

.ExchangeBeta-form-right {
  padding-right: 0.5rem;
}

.ExchangeBeta-form-left, .ExchangeBeta-form-right {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.ExchangeBeta-form input {
  width: 12rem;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
}

.ExchangeBeta-form-large-font {
  font-size: 1.3rem;
}

.ExchangeBeta-unit {
  color: white;
  text-decoration: none;
  font-weight: normal;
}

.ExchangeBeta-form-subtitle {
  font-size: 0.95rem;
}

.ExchangeBeta-form-max {
  text-align: right;
  font-size: 0.9rem;
  opacity: 0.8;
  white-space: nowrap;
}

.ExchangeBeta-form-swap {
  cursor: pointer;
  text-align: center;
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  background: rgba(255, 255, 255, 0.15);
  border: 3px solid rgba(255, 255, 255, 0.2);
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}

.ExchangeBeta-form-swap:hover {
  opacity: 1;
}

.ExchangeBeta-form-swap-icon {
  text-align: center;
  display: block;
  padding-bottom: 0.9rem;
  transform: rotate(90deg);
}

@media (max-width: 700px) and (min-width: 550px) {
  .ExchangeBeta-form-max {
    padding-right: 2rem;
  }

  .ExchangeBeta-form input {
    width: 18rem;
  }
}

.ExchangeBeta-small-login {
  display: none;
}

.ExchangeBeta-small-stats {
  display: none;
}

@media (max-width: 700px) {
  .ExchangeBeta-small-stats {
    display: block;
  }
  .ExchangeBeta-small-stats td {
    display: block;
    width: 100%;
  }
  .ExchangeBeta-box-info {
    left: 0;
    top: 100%;
  }
  .ExchangeBeta-box-stats-number {
    font-size: 1.1rem;
  }
  .ExchangeBeta-box-stats-label {
    font-size: 0.85rem;
  }
  .ExchangeBeta-box-stats-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
  .ExchangeBeta-box-stats {
    width: 100%;
  }
  .ExchangeBeta-title {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
  }
  .ExchangeBeta-small-login {
    margin-right: 1rem;
    display: block;
  }
  .ExchangeBeta-right {
    display: none;
  }
  .ExchangeBeta-widget {
    width: 100%;
  }
  .ExchangeBeta-wallet-box {
    width: 100%;
  }
  .ExchangeBeta-chainlink-small-box {
    display: block;
  }
}
