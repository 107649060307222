.Migration .Box {
  margin: auto;
  margin-top: 1.5rem;
}

.MigrationModal .Modal-content {
  width: 15rem;
}

.MigrationModal-input-container {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.MigrationModal input {
  width: 100%;
}

.Migration-title {
  color: white;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.Migration-note {
  text-align: center;
}

.Migration-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
}

.MigrationModal-info-box {
}

@media (max-width: 800px) {
  .Migration-cards {
    grid-template-columns: 1fr;
  }

  .MigrationModal .Modal-content {
    width: auto;
  }
}
