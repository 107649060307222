.Exchange {
  padding-top: 1rem;
  overflow: visible;
}

.Exchange-market-sponsor {
  margin-bottom: 1rem;
  margin-top: 0.3rem;
}

.Exchange-info-read-more {
  margin-top: 1rem;
}

.Exchange-market-sponsor a {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.Exchange-market-selector {
  border: 1px solid rgba(0, 0, 0, 0.7);
  background: #3a3345;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  display: block;
  border-radius: 9px;
  margin-bottom: 0.5rem;
  position: relative;
}

.Exchange-market-selector .select-caret {
  top: 0.85rem;
}

.Exchange-market-selector select {
  background: #3a3345;
  width: 100%;
  height: 2.5rem;
  font-size: 1.1rem;
  -webkit-appearance:none;
}

.Exchange-fee-box {
  line-height: 1.7rem;
}

.Exchange-warning-box {
  margin-bottom: 1.5rem;
  color: #c067e6;
  display: grid;
  grid-template-columns: 1fr auto;
  line-height: 1.5rem;
}

.Exchange-warning-close-icon {
  font-size: 0.7rem;
  cursor: pointer;
  opacity: 0.8;
  margin-left: 1rem;
}

.Exchange-warning-close-icon:hover {
  opacity: 0.9;
}

.Exchange-warning-box a {
  color: #c067e6;
}

.Exchange-rewards-info {
  margin-bottom: 0.5rem;
}

.Exchange-rewards-bull {
  color: #c067e6;
}

.Exchange-rewards-separator {
  height: 0.5rem;
}

.Exchange-rewards-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.Exchange-rewards-bear {
  color: #5d6dfc;
}

.Exchange-markets-menu-title {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1rem;
}

.Exchange-markets-menu-subtitle {
  opacity: 0.8;
}

.Exchange-markets-close {
  font-size: 0.7rem;
  opacity: 0.7;
}

.Exchange-markets-close:hover {
  opacity: 0.8;
}

.Exchange-form-pay-row {
  display: grid;
  grid-template-columns: auto auto;
}

.Exchange-form-market-row {
  display: grid;
  grid-template-columns: auto auto;
}

.Exchange-form-star {
  text-align: right;
  font-size: 0.9rem;
}

.Exchange-form-star-icon {
  vertical-align: middle;
  margin-right: 0.4rem;
  margin-top: -0.2rem;
}

a.Exchange-markets-menu-market {
  text-decoration: none;
  color: white;
  opacity: 0.9;
}

a.Exchange-markets-menu-market:hover {
  opacity: 1;
}

.Exchange-markets-menu.modal-box {
  width: 15rem;
  position: relative;
  overflow: hidden;
  background: black;
}

.Exchange-markets-menu-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(93,109,252,0.5) 0%, rgba(192,103,230,0.5) 100%);
  border: 1px solid rgba(192,103,230,0.3);
  z-index: 1;
}

.Exchange-markets-menu-content {
  position: relative;
  z-index: 2;
}

.Exchange .Box {
  width: auto;
  max-width: 80rem;
}

.Exchange-price-tooltip {
  background: white;
  color: black;
  font-size: 0.9rem;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
}

.Exchange-button-overlay {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.3s ease;
}

.Exchange-button-content {
  position: relative;
  z-index: 1;
}

.Exchange-form.bull .Exchange-button-overlay {
  background: linear-gradient(270deg, rgba(139,106,242,1) 0%, rgba(192,103,230,1) 100%);
}

.Exchange-form.bear .Exchange-button-overlay {
  background: linear-gradient(270deg, rgba(93,109,252,1) 0%, rgba(140,106,242,1) 100%);
}

.Exchange-form button.button-primary {
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease;
}

.Exchange-form button.button-primary:hover .Exchange-button-overlay {
  opacity: 1;
}

.Exchange-form button.button-primary:hover {
  opacity: 1;
}

.Exchange-form button.button-primary:active {
  background: linear-gradient(270deg, rgba(93,109,252,0.8) 0%, rgba(192,103,230,0.8) 100%);
}

.Exchange-form button.button-primary:active .Exchange-button-overlay {
  visibility: hidden;
}

.Exchange-form button.button-primary:disabled {
  background: linear-gradient(270deg, rgba(93,109,252,1) 0%, rgba(192,103,230,1) 100%);
  opacity: 0.9;
}

.Exchange-form button.button-primary:disabled .Exchange-button-overlay {
  background: none;
}

.Exchange-bull-bear-info-row {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-column-gap: 1rem;
  margin-bottom: 0.1rem;
}

.Exchange-info-bull, .Exchange-info-bear {
  opacity: 0.7;
}

.Exchange-info-label {
  margin-top: 0.1rem;
  font-size: 0.9rem;
  text-align: center;
  visibility: hidden;
}

a.Exchange-info-total {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

.Exchange-bull-bear-connect-wallet {
  margin-top: 0.8rem;
}

.Exchange-info-hr {
  height: 1px;
  width: 100%;
  margin-top: 0.7rem;
  opacity: 0.3;
  border-top: 4px dotted white;
  visibility: hidden;
}

.Exchange-bull-bear-info-row:hover .Exchange-info-label {
  visibility: visible;
}

.Exchange-bull-bear-info-row:hover .Exchange-info-hr {
  visibility: visible;
}

.Exchange-info-hr.bull {
}

.Exchange-info-hr.bear {
}

.Exchange-bull-bear-info-v1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Exchange-bull-bear-info table {
  width: 100%;
}

.Exchange-bull-bear-info-bear {
  text-align: right;
}

.Exchange-bull-bear-lev, .Exchange-funding {
}

.Exchange-info-bear {
  text-align: right;
}

.Exchange-bull-bear-bar {
  margin-top: 0.8rem;
  height: 3px;
  border-radius: 3px;
  margin-bottom: 0.8rem;
  background: linear-gradient(90deg, rgba(192,103,230,1) 0%, rgba(192,103,230,1) 45%, rgba(93,109,252,1) 55%, rgba(93,109,252,1) 100%);
}

.Exchange-bull-bear-total-info a {
  font-size: 1.1rem;
  color: white;
  text-decoration: none;
}

.Exchange-bull-bear-info-bear {
  text-align: right;
}

.Exchange-bull-bear-info-bull {
}

.Exchange-main {
  display: grid;
  grid-template-columns: auto 1fr;
}

.Exchange-widget-container {
  padding-left: 2.5rem;
}

.Exchange-banner {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 0.5rem;
  line-height: 1.8rem;
}

.Exchange-chart-container {
  position: relative;
}

.Exchange-chart-options {
  position: absolute;
  right: 0.8rem;
  bottom: 0.5rem;
}

.Exchange-chart-option {
  cursor: pointer;
  display: inline-block;
  width: 2.5rem;
  text-align: center;
  opacity: 0.5;
}

.Exchange-chart-option:hover {
  opacity: 0.7;
}

.Exchange-chart-option.active {
  opacity: 1;
}

.Exchange-close-icon {
  display: block;
  margin-top: 0.25rem;
  cursor: pointer;
}

.Exchange-box-info {
  background: #18191f;
  z-index: 3;
  border-radius: 12px;
  padding: 1rem;
  text-align: left;
  position: absolute;
  right: 100%;
  width: 20rem;
  top: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: none;
}

a.Exchange-pending-tx {
  text-decoration: none;
  color: #5d6dfc;
}

a.Exchange-pending-tx.active {
  color: #c067e6;
}

.Exchange-box-stats:hover .Exchange-box-info {
  display: block;
}

.Exchange-stats-updated-time {
  font-size: 0.8rem;
}

.Exchange-chainlink-logo-box img {
  height: 3rem;
}

.Exchange-chainlink-logo-box {
  margin-bottom: 0.3rem;
}

.Exchange-chainlink-small-box {
  padding: 0.5rem;
  padding-left: 1rem;
  display: none;
}

.Exchange-chainlink-box {
}

.Exchange-bear-box {
}

.Exchange-bull-box {
}

.Exchange-price-box {
}

.Exchange-chainlink-small-box img {
  height: 2rem;
}

.Exchange-chainlink-logo-small-box {
  margin-bottom: 0.3rem;
}

.Exchange-box-stats {
  position: relative;
  margin-left: auto;
  text-align: center;
  width: 13rem;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem;
  margin-bottom: 0.8rem;
}

.Exchange-rewards-title {
  color: #c067e6;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  transition: color 0.2s ease;
}

.Exchange-wallet-title {
  color: #c067e6;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  transition: color 0.2s ease;
}

.Exchange-wallet-container.bull .Exchange-wallet-title {
  color: rgba(192,103,230,1);
}

.Exchange-wallet-container.bear .Exchange-wallet-title {
  color: rgba(93,109,252,1);
}

.Exchange-rewards-container.bull .Exchange-rewards-title {
  color: rgba(192,103,230,1);
}

.Exchange-rewards-container.bear .Exchange-rewards-title {
  color: rgba(93,109,252,1);
}

.Exchange-wallet-title-icon {
  margin-right: 0.5rem;
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.Exchange-rewards-title-icon {
  margin-right: 0.5rem;
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.Exchange-bull-delta, .Exchange-bear-delta {
  font-size: 1rem;
}

.Exchange-bull-delta {
  color: #c067e6;
}

.Exchange-bear-delta {
  color: #5d6dfc;
}

.Exchange-sell-divider.bull {
  color: #c067e6;
}

.Exchange-sell-divider.bear {
  color: #5d6dfc;
}

.Exchange-sell-button-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.Exchange-sell-filler {
  border-top: 4px dotted white;
  margin-right: 0.5rem;
  opacity: 0.8;
}

.Exchange-sell-filler.bull {
  border-color: #c067e6;
}

.Exchange-sell-filler.bear {
  border-color: #5d6dfc;
}

button.Exchange-rewards-claim-button {
  text-align: right;
  padding: 0.3rem 0.5rem;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: background-color 0.2s ease, color 0.2s ease;
}

button.Exchange-rewards-claim-button.bull {
  color: #c067e6;
}

button.Exchange-rewards-claim-button.bear {
  color: #5d6dfc;
}

button.Exchange-rewards-claim-button:hover {
  color: white;
  opacity: 1;
}

button.Exchange-rewards-claim-button.bull:hover {
  background: #c067e6;
}

button.Exchange-rewards-claim-button.bear:hover {
  background: #5d6dfc;
}

button.Exchange-rewards-claim-button:active {
  opacity: 0.8;
}

button.Exchange-rewards-claim-button.bull:disabled {
  background: none;
  color: #c067e6;
}

button.Exchange-rewards-claim-button.bear:disabled {
  background: none;
  color: #5d6dfc;
}


.Exchange-wallet-box button.Exchange-sell-button {
  text-align: right;
  padding: 0.3rem 0.5rem;
  border-radius: 6px;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.Exchange-wallet-box button.Exchange-sell-button.bull {
  color: #c067e6;
}

.Exchange-wallet-box button.Exchange-sell-button.bear {
  color: #5d6dfc;
}

.Exchange-wallet-box button.Exchange-sell-button:hover {
  color: white;
  opacity: 1;
}

.Exchange-wallet-box button.Exchange-sell-button.bull:hover {
  background: #c067e6;
}

.Exchange-wallet-box button.Exchange-sell-button.bear:hover {
  background: #5d6dfc;
}

.Exchange-wallet-box button.Exchange-sell-button.bull:disabled {
  background: none;
  color: #c067e6;
}

.Exchange-wallet-box button.Exchange-sell-button.bear:disabled {
  background: none;
  color: #5d6dfc;
}

.Exchange-wallet-box button.Exchange-sell-button:active {
  opacity: 0.8;
}

.Exchange-wallet-box.Exchange-box {
  margin-top: 0.8rem;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  position: relative;
  overflow: hidden;
}

.Exchange-rewards-box.Exchange-box {
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}

.Exchange-wallet-box table {
  padding-bottom: 0.3rem;
  width: 100%;
}

.Exchange-wallet-icon {
  display: inline-block;
  margin-right: 0.6rem;
  margin-bottom: -0.1rem;
}

.Exchange-wallet-box table td {
  padding-top: 0rem;
  padding-bottom: 0.2rem;
  padding-right: 0;
}

.Exchange-wallet-button-box {
  text-align: right;
}

.Exchange-wallet-label {
  padding-top: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.8;
  padding-left: 1.5rem;
}

.Exchange-wallet-value {
  padding-left: 1.5rem;
}

.Exchange-box-stats-number {
  font-size: 1.3rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.Exchange-box-stats-label {
  font-size: 0.9rem;
  opacity: 0.8;
}

.Exchange-layout {
  width: 100%;
}

.Exchange-title {
  color: white;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.Exchange-main-title.Exchange-title {
  margin-bottom: 0.3rem;
  position: relative;
}

.Exchange-title-text {
  font-size: 1.2rem;
  opacity: 1;
  background: linear-gradient(270deg, rgba(93,109,252,0.7) 0%, rgba(192,103,230,0.7) 100%);
  border-radius: 6px;
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.5rem;
}

.Exchange-menu-button {
  font-size: 1.2rem;
  text-align: right;
  opacity: 0.9;
}

.Exchange-main-title:hover .Exchange-menu-button, .Exchange-main-title:hover .Exchange-title-text {
  opacity: 1;
}

.Exchange-dropdown-menu {
  z-index: 3;
  position: absolute;
  width: 100%;
  background: #101014;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}

a.Exchange-dropdown-menu-item {
  text-decoration: none;
  color: white;
  display: block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Exchange-dropdown-menu-item:hover {
  background: linear-gradient(270deg, rgba(93,109,252,1) 0%, rgba(192,103,230,1) 100%);
}

.Exchange-search-bar {
  text-align: right;
}

.Exchange-search-bar input {
  width: 100%;
  font-size: 0.9rem;
  height: 0.8rem;
}

.Exchange-title .bull-bear-button {
  font-weight: bold;
}

.Exchange-widget-top {
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.Exchange-login-container {
  text-align: right;
  margin-right: 0.5rem;
}

.Exchange-login-container button {
  padding: 0;
  color: #c067e6;
}

.bull-bear-button {
  margin-right: 2rem;
}

.bull-bear-button.bull, .bull-bear-button.bull .radial-option {
  border-color: #c067e6;
}

.bull-bear-button.bull .radial-option-center {
  background: #c067e6;
}

.bull-bear-button.bear, .bull-bear-button.bear .radial-option {
  border-color: #5d6dfc;
}

.bull-bear-button.bear .radial-option-center {
  background: #5d6dfc;
}

.Exchange-title .bull {
  color: #c067e6;
}

.Exchange-title .bear {
  color: #5d6dfc;
}

.Exchange-main-price {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.Exchange-title-content {
}

.Exchange-login-container {
  display: inline-block;
}

.Exchange-widget {
  width: 24rem;
  padding-bottom: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 40px;
  margin-bottom: 1rem;
}

.Exchange-widget-left {
  margin-left: 1.5rem;
}

.Exchange-form table {
  width: 100%;
}

.Exchange-form table td {
  padding-right: 0;
}

.Exchange-form button {
  background: linear-gradient(270deg, rgba(93,109,252,1) 0%, rgba(192,103,230,1) 100%);
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.Exchange-form.bull button {
  border: 1px solid rgba(192,103,230,0.5);
}

.Exchange-form.bear button {
  border: 1px solid rgba(93,109,252,0.5);
}

.Exchange-form-top {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
}

.Exchange-form.Exchange-box {
  padding-top: 0.3rem;
}

.Exchange-box {
  border-radius: 12px;
  background: linear-gradient(0deg, rgba(93,109,252,0.12) 0%, rgba(192,103,230,0.12) 100%);
  padding: 0.8rem 1rem;
  width: 18.5rem;
  border-width: 1px;
  border-style: solid;
}

.Exchange-box {
  transition: border-color 0.2s ease;
}

.Exchange-box.bull {
  border-color: rgba(192, 103, 230, 0.5);
}

.Exchange-box.bear {
  border-color: rgba(93, 109, 252, 0.5);
}

.Exchange-form-left {
  padding-left: 1.5rem;
  width: 10rem;
}

.Exchange-form-right {
  padding-right: 0.5rem;
}

.Exchange-form-left, .Exchange-form-right {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.Exchange-form input {
  font-size: 1.3rem;
  width: 12rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.7);
}

.Exchange-form.bull input.input-outline:focus {
  outline: none;
  border-color: rgba(192, 103, 230, 0.4);
}

.Exchange-form.bear input.input-outline:focus {
  outline: none;
  border-color: rgba(93, 109, 252, 0.4);
}

.Exchange-asset-label {
  font-size: 1.3rem;
}

.Exchange-form-receive {
  margin-top: 1rem;
}

.Exchange-form-large-font {
  font-size: 1.3rem;
}

.Exchange-unit {
  color: white;
  text-decoration: none;
  font-weight: normal;
}

.Exchange-form-subtitle {
  font-size: 0.95rem;
}

.Exchange-form-max {
  text-align: right;
  font-size: 0.9rem;
  white-space: nowrap;
}

.Exchange-reserve-row {
  display: grid;
  grid-template-columns: auto auto;
}

.Exchange-reserve-info {
  vertical-align: middle;
  margin-bottom: 0.1rem;
}

.Exchange-more-info {
  display: inline-block;
  margin-top: 1rem;
  opacity: 0.7;
}

.Exchange-more-info:hover {
  opacity: 0.9;
}

.Exchange-chainlink img {
  height: 1rem;
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.Exchange-chainlink {
  display: none;
  margin-top: 3.2rem;
  font-size: 0.9rem;
}

.Exchange-form-swap {
  cursor: pointer;
  text-align: center;
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(0deg, rgba(93,109,252,0.8) 0%, rgba(192,103,230,0.8) 100%);
  border: 3px solid rgba(192, 103, 230, 0.8);
  font-size: 1.5rem;
  color: white;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.Exchange-form.bull .Exchange-form-swap {
  border-color: rgba(192,103,230,0.5);
}

.Exchange-form.bear .Exchange-form-swap {
  border-color: rgba(93,109,252,0.5);
}

.Exchange-form .Exchange-form-swap:hover .Exchange-button-overlay {
  opacity: 1;
}

.Exchange-form-swap:active {
  background: linear-gradient(0deg, rgba(93,109,252,0.6) 0%, rgba(192,103,230,0.6) 100%);
}

.Exchange-form-swap:active .Exchange-button-overlay {
  visibility: hidden;
}

.Exchange-form-swap-icon {
  text-align: center;
  display: block;
  padding-bottom: 0.5rem;
  transform: rotate(90deg);
}

@media (max-width: 700px) and (min-width: 550px) {
  .Exchange-form input {
    width: 20rem;
  }
}

.Exchange-small-login {
  display: none;
}

.Exchange-small-stats {
  display: none;
}

@media (max-width: 700px) {
  .Exchange {
    padding: 2rem;
    padding-top: 1.5rem;
  }
  .Exchange-main {
    grid-template-columns: 1fr;
  }
  .Exchange-widget-top {
    margin-top: 1rem;
  }
  .Exchange-widget-container {
    padding-left: 0;
  }
  .Exchange-form {
    width: auto;
  }
  .Exchange-form input {
    width: 15rem;
  }
  .Exchange-wallet-container {
    width: auto;
  }
  .Exchange-wallet-box {
    width: auto;
  }
  .Exchange-rewards-box {
    width: auto;
  }
}
