.X2Launcher {
  margin-bottom: 3rem;
  overflow: visible;
}

.X2Launcher-title-box {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 1rem;
}

.X2Launcher-title {
  font-size: 1.1rem;
}

.X2Launcher-selector {
  margin-top: 0.3rem;
  background: #404042;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 0.8rem;
  position: relative;
}

.X2Launcher-form input {
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
}

.X2Launcher-form {
}

.X2Launcher-warning-box {
}
