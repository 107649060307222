.Modal {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.Modal-content {
  z-index: 20;
  position: relative;
  background: #18191f;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  max-width: 100%;
  margin: 1rem;
}

.Modal-title-bar {
  display: grid;
  grid-template-columns: auto auto;
  padding-bottom: 0.5rem;
}

.Modal-title {
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}
