.Dashboard-login, .Dashboard-account-status {
  margin-left: auto;
}

.Dashboard-warning {
  margin-bottom: 1rem;
  color: #c91b00;
}

.Dashboard-max-supply {
  margin-bottom: 1.5rem;
}

.Dashboard-withdrawal-time-remaining, .Dashboard-finalise-withdrawal {
  font-size: 0.9rem;
  opacity: 0.9;
}

.Dashboard-wallet-box {
  width: 100%;
  margin-bottom: 0.5rem;
}

.Dashboard-withdraw-button {
  display: inline-block;
}

.Dashboard-wallet-box td {
  padding: 0;
  padding-bottom: 0.5rem;
}

.Dashboard-bottom-box {
  margin-top: 1rem;
}

.Dashboard-xvix-balance {
}

.Dashboard-burn-info {
  margin-top: 1rem;
}

.Dashboard-burn-widget {
  margin-top: 1rem;
}

.Dashboard-burn-box-form {
  margin-top: 0.5rem;
}

.Dashboard-lp-xvix-max-value {
  margin-top: 0.5rem;
}

.Dashboard-add-liquidity-button {
  margin-top: 0.8rem;
  width: 14rem;
}

.Dashboard-burn-options {
  margin-top: 0.5rem;
  background: #404042;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
}

.Dashboard-burn-option.active {
  opacity: 1;
}

.Dashboard-vault-box {
  margin-top: 0.5rem;
}

input.input-outline.Dashboard-input-outline {
  display: inline-block;
  margin-right: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
}

button.Dashboard-button-primary {
  display: inline-block;
  border-radius: 9px;
  background: #3a49f2;
  padding: 0.5rem 1rem;
  opacity: 0.9;
}

.Dashboard-withdraw-max-value {
  margin-bottom: 0.3rem;
}

.Dashboard-withdraw-info-button-box {
  margin-top: 0.7rem;
  text-align: center;
}

.Dashboard-withdraw-info-button-box button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.Dashboard-withdraw-modal-top {
  display: grid;
  grid-template-columns: auto auto;
}

.Dashboard-withdraw-modal-close {
  opacity: 0.6;
  margin-left: auto;
  font-size: 0.7rem;
  margin-top: 0.1rem;
}

.Dashboard-withdraw-modal-close:hover {
  opacity: 0.8;
}

button.Dashboard-button-primary.Dashboard-withdraw-button-primary {
  margin-top: 0.8rem;
  width: 100%;
}

button.Dashboard-button-primary:hover {
  opacity: 1;
  background: #3d4dff;
}

button.Dashboard-button-primary:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.Dashboard-supply-square {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 3px;
}

.Dashboard-wallet-buy {
  margin-top: 0.5rem;
  margin-left: auto;
  opacity: 0.9;
}

.Dashboard-wallet-buy:hover {
  opacity: 1;
}

.Dashboard-wallet-buy-icon {
  font-size: 0.9rem;
}

.Dashboard-max-value {
  opacity: 0.8;
  cursor: pointer;
}

.Dashboard-max-value:hover {
  opacity: 0.9;
}

.Dashboard-box {
  background: #18191f;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  width: 13rem;
  margin-left: auto;
}

.Dashboard-box-inner {
  padding: 1rem;
}

.Dashboard-box a {
  text-decoration: none;
  color: white;
  opacity: 0.9;
  text-align: right;
  font-size: 0.8rem;
}

.Dashboard-box-label {
  font-size: 0.9rem;
  opacity: 0.9;
  display: grid;
  grid-template-columns: auto auto;
}

.Dashboard-box-label-link {
  text-align: right;
}

.Dashboard-top-box {
  width: 100%;
}

.Dashboard-supply-label-box {
  margin-left: 1rem;
}

.Dashboard-supply-label {
  font-size: 0.9rem;
  line-height: 1rem;
  opacity: 0.8;
}

.Dashboard-hr.hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Dashboard-supply-chart-total {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dashboard-supply-chart-box {
  position: relative;
}

.DashboardApp {
  margin-bottom: 2rem;
}

.Dashboard-title-box {
  width: 100%;
}

.Dashboard-withdraw-title {
  margin-bottom: 0.5rem;
}

.Dashboard-withdraw-modal.modal-box {
  width: 14rem;
}

.Dashboard-withdraw-liquidity-modal.modal-box {
  width: 16rem;
}

.Dashboard-withdraw-liquidity-modal input {
  width: 10rem;
}

.Dashboard-withdraw-liquidity-xvix-value {
  margin-top: 0.5rem;
}

.Dashboard-title {
  color: white;
  font-size: 1.1rem;
}

.Dashboard-subtitle {
}

.Dashboard-timelock-vault-title {
  /* color: #5625db; */
}

.Dashboard-wallet-title {
  /* color: #ff8126; */
}

.DistributionApp-warning-icon {
  padding-right: 0.6rem;
}

.Dashboard-login a {
  font-size: 1rem;
  text-decoration: none;
}

.Dashboard-view-info {
}

.DistributionApp-back-button {
  margin-left: 0.5rem;
}

.Dashboard-advanced-settings-button {
  cursor: pointer;
}

.Dashboard-info-button {
  font-weight: normal;
  cursor: pointer;
}

.Dashboard-more-settings-icon {
  vertical-align: middle;
}

.Dashboard-hide-settings-icon {
  vertical-align: middle;
}

.LPApp-pair-button {
  min-width: 5rem;
  margin-right: 1rem;
  padding: 0.1rem;
}

.LPApp-button {
  padding: 0.1rem;
}

.LPApp-button.active {
  background: #c2c2c2;
  color: black;
}

.LPApp-pair-button.active {
  background: #c2c2c2;
  color: black;
}

.LPApp-settings-button {
  margin-right: 1rem;
  padding: 0.1rem;
}

.LPApp-settings-button.active {
  background: #c2c2c2;
  color: black;
}

.LPApp-slippage-input {
  width: 4rem;
  padding: 0.1rem;
}

.LPApp-slippage-container.active .LPApp-slippage-input {
  background: #c2c2c2;
  color: black;
}

.LPApp-slippage-container {
  position: relative;
}

.LPApp-slippage-symbol {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 1rem;
  right: 0rem;
  margin-top: -0.1rem;
  top: 0;
  bottom: 0;
}

.LPApp-slippage-container.active .LPApp-slippage-symbol {
  background: #c2c2c2;
  color: black;
}

.Dashboard-approve-button {
  min-width: 9rem;
  margin-left: 0.5rem;
}

.Dashboard-account-url {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FloorPrice-large {
  display: block;
}

.FloorPrice-small {
  display: none;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .Dashboard-account-url {
    max-width: 15rem;
  }
  .FloorPrice-large {
    display: none;
  }
  .FloorPrice-small {
    display: block;
  }
  .Dashboard-box {
    margin-left: 0;
    display: block;
    width: 100%;
  }
}
