.Stake {
  padding-bottom: 1.5rem;
  overflow: visible;
}

.Stake-warning {
  border: 1px solid #2d2d2d;
  border-radius: 6px;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background: #262833;
}

.Stake-x2fs-info {
  margin-bottom: 1rem;
}

.Stake .Box {
  width: auto;
  max-width: 70rem;
}

.Stake-title-box {
  width: 100%;
}

.Stake-option-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Stake-option {
  text-align: center;
  border: 1px solid #2d2d2d;
  border-radius: 9px;
  padding: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.Stake-modal-option-title {
  margin-bottom: 1rem;
}

.Stake-max-value {
  margin-bottom: 0.3rem;
}

.Stake-option:hover {
  background: #262833;
}

.Stake-button-primary-xlge {
  margin-top: 0.8rem;
}

.Stake-option-modal {
  width: 14rem;
}

.Stake-xlge-modal input {
  display: block;
}

button.Stake-button-primary {
  width: 100%;
  border-radius: 9px;
  background: #3a49f2;
  padding: 0.5rem 1rem;
  opacity: 0.9;
}

button.Stake-button-primary:hover {
  opacity: 1;
  background: #3d4dff;
}

button.Stake-button-primary:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.Stake-title {
  color: white;
  font-size: 1.1rem;
}

.Stake-modal-top {
  display: grid;
  grid-template-columns: auto auto;
}

.Stake-modal-back {
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 0.3rem;
  opacity: 0.6;
}

.Stake-modal-back:hover {
  opacity: 0.8;
}

.Stake-modal-close {
  opacity: 0.6;
  margin-left: auto;
  font-size: 0.7rem;
  margin-top: 0.3rem;
}

.Stake-modal-close:hover {
  opacity: 0.8;
}

.Stake-card-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Stake-card {
  border: 1px solid #2d2d2d;
  border-radius: 9px;
  margin-right: 1rem;
  padding: 1rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
}

.Stake-card-options {
  margin-top: 1rem;
}

.Stake-card-option {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 1rem;
}

.Stake-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.Stake-card-fee {
}

.Stake-card-yield {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .Stake-card-container {
    grid-template-columns: 1fr;
  }
}
